module.exports={
  "_args": [
    [
      {
        "raw": "webvr-polyfill@^0.9.40",
        "scope": null,
        "escapedName": "webvr-polyfill",
        "name": "webvr-polyfill",
        "rawSpec": "^0.9.40",
        "spec": ">=0.9.40 <0.10.0",
        "type": "range"
      },
      "/home/ubuntu/a-frobot/aframe"
    ]
  ],
  "_from": "webvr-polyfill@>=0.9.40 <0.10.0",
  "_id": "webvr-polyfill@0.9.40",
  "_inCache": true,
  "_location": "/webvr-polyfill",
  "_nodeVersion": "8.6.0",
  "_npmOperationalInternal": {
    "host": "s3://npm-registry-packages",
    "tmp": "tmp/webvr-polyfill-0.9.40.tgz_1507657755590_0.00047161197289824486"
  },
  "_npmUser": {
    "name": "jsantell",
    "email": "jsantell@gmail.com"
  },
  "_npmVersion": "5.3.0",
  "_phantomChildren": {},
  "_requested": {
    "raw": "webvr-polyfill@^0.9.40",
    "scope": null,
    "escapedName": "webvr-polyfill",
    "name": "webvr-polyfill",
    "rawSpec": "^0.9.40",
    "spec": ">=0.9.40 <0.10.0",
    "type": "range"
  },
  "_requiredBy": [
    "/"
  ],
  "_resolved": "https://registry.npmjs.org/webvr-polyfill/-/webvr-polyfill-0.9.40.tgz",
  "_shasum": "2cfa0ec0e0cc6ba7238c73a09cba4952fff59a63",
  "_shrinkwrap": null,
  "_spec": "webvr-polyfill@^0.9.40",
  "_where": "/home/ubuntu/a-frobot/aframe",
  "authors": [
    "Boris Smus <boris@smus.com>",
    "Brandon Jones <tojiro@gmail.com>",
    "Jordan Santell <jordan@jsantell.com>"
  ],
  "bugs": {
    "url": "https://github.com/googlevr/webvr-polyfill/issues"
  },
  "dependencies": {},
  "description": "Use WebVR today, on mobile or desktop, without requiring a special browser build.",
  "devDependencies": {
    "chai": "^3.5.0",
    "jsdom": "^9.12.0",
    "mocha": "^3.2.0",
    "semver": "^5.3.0",
    "webpack": "^2.6.1",
    "webpack-dev-server": "2.7.1"
  },
  "directories": {},
  "dist": {
    "integrity": "sha512-m7jhJHjFcUYPyPSNeGmly7a2h/cP7bARz0OZMoUn5SueVXEKeZ4P7bzbAUDBDvvqCsa5gHgM3PFIhYe13bqaWw==",
    "shasum": "2cfa0ec0e0cc6ba7238c73a09cba4952fff59a63",
    "tarball": "https://registry.npmjs.org/webvr-polyfill/-/webvr-polyfill-0.9.40.tgz"
  },
  "gitHead": "45828ffdb8c3e0f9bb90296d6039d3cc7909ba8e",
  "homepage": "https://github.com/googlevr/webvr-polyfill",
  "keywords": [
    "vr",
    "webvr"
  ],
  "license": "Apache-2.0",
  "main": "src/node-entry",
  "maintainers": [
    {
      "name": "jsantell",
      "email": "jsantell@gmail.com"
    },
    {
      "name": "toji",
      "email": "tojiro@gmail.com"
    },
    {
      "name": "smus",
      "email": "boris@smus.com"
    }
  ],
  "name": "webvr-polyfill",
  "optionalDependencies": {},
  "readme": "ERROR: No README data found!",
  "repository": {
    "type": "git",
    "url": "git+https://github.com/googlevr/webvr-polyfill.git"
  },
  "scripts": {
    "build": "webpack",
    "start": "npm run watch",
    "test": "mocha",
    "watch": "webpack-dev-server"
  },
  "version": "0.9.40"
}
